<template>
  <div class="auth__form__content" id="auth-login">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)" class="is-label-14px-normal">
        <h2 class="has-text-centered has-text-weight-black is-size-1">
          Forgot Password
        </h2>
        <ValidationProvider
          name="Email"
          v-slot="{ errors }"
          rules="required|email"
        >
          <b-field
            label="Write Down your Email"
            :type="errors[0] && 'is-danger'"
            :message="errors[0]"
          >
            <b-input placeholder="Your email" v-model="email"></b-input>
          </b-field>
        </ValidationProvider>
        <b-field v-if="isSuccess" class="column">
          <b-message type="is-success" has-icon>
            {{ successMessage }}
          </b-message>
        </b-field>
        <b-field class="is-flex is-justify-content-center is-full password-btn">
          <b-button class="is-fullwidth" native-type="submit" type="is-success">Send</b-button>
        </b-field>
      </form>
    </ValidationObserver>
    <div class="auth__form__footer is-flex is-justify-content-center">
      <p class="is-size-6 pt-2 password-text-span">
        Remember your password?
        <router-link to="login" class="is-link has-text-danger-dark">
          Login
        </router-link>
      </p>
    </div>
  </div>
</template>
<script>
import ApiService from '@/services/common/api.service'

export default {
  data() {
    return {
      email: '',
      isLoading: false,
      isSuccess: false,
      successMessage: 'Check your email to reset your password',
    }
  },
  methods: {
    async submit() {
      this.isLoading = true
      try {
        await ApiService.post('/api/space-roketin/forgot-password', {
          workEmail: this.email,
        })
        this.isSuccess = true
      } catch (err) {
        this.isSuccess = true
      }
      this.isLoading = false

      this.email = ''
      requestAnimationFrame(() => {
        this.$refs.observer.reset()
      })
    },
  },
}
</script>
